<template>
    <section class="container">
        <stockTopMenu curPosition="发起工单" windowId="InitiateWorkOrder" module="workorder"></stockTopMenu>
        <div class="top" style="margin-top: 10px">
            <div class="top-tab"></div>
            <div class="top-btn" :style="formState || typeForm ? 'marginRight: 330px;' : ''">
                <el-button type="primary" @click="addnew"> 新增工单 </el-button>
            </div>
        </div>
        <div style="height: 89vh; display: flex; padding: 0 0px 20px 20px">
            <grid-manager :option="gridOption"></grid-manager>
            <SearchFormItem
                :options="options"
                @searchHandel="sureSharch"
                @getState="getFormState"
                style="margin-top: -36px"
            ></SearchFormItem>
        </div>
        <billDetails
            v-if="billDetailsModule"
            @close="
                () => {
                    billDetailsModule = false
                }
            "
            :modalParams="modalParams"
        ></billDetails>
        <newWork v-if="AddCursoreModule" @close="closeDialog" :modalParams="modalParams"></newWork>
    </section>
</template>
<script>
import newWork from './compoments/newWork'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import { reimbursePerson, wosQueryType } from '@/libs/http/api.js'
import billDetails from './compoments/billDetails.vue'
import stockTopMenu from '../../components/stockTopMenu.vue'
export default {
    name: 'InitiateWorkOrder',
    components: {
        newWork,
        billDetails,
        SearchFormItem,
        stockTopMenu
    },
    data() {
        return {
            billDetailsModule: false,
            gridOption: {
                gridManagerName: 'InitiateWorkOrder',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: false,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '20px',
                columnData: [
                    { key: 'snNumber', text: '工单编号' },
                    {
                        key: 'state',
                        text: '状态',

                        template: () => {
                            return `
           <div v-if="row.state == -1" class="row align-center">
                  <span style="display: inline-block;width:5px;height: 5px;background: #F8475F;border-radius:50%;margin-right:5px"></span>
                  已关闭
                </div>
                <div v-if="row.state == 0" class="row align-center">
                  <span style="display: inline-block;width:5px;height: 5px;background: #067CF2;border-radius:50%;margin-right:5px"></span>
                  已发起
                </div>
                <div v-if="row.state == 1" class="row align-center">
                  <span style="display: inline-block;width:5px;height: 5px;background: #BD73D3;border-radius:50%;margin-right:5px"></span>
                  已受理
                </div>
                <div v-if="row.state == 2" class="row align-center">
                  <span style="display: inline-block;width:5px;height: 5px;background: #FF9000;border-radius:50%;margin-right:5px"></span>
                  已转交
                </div>
                <div v-if="row.state == 3" class="row align-center">
                  <span style="display: inline-block;width:5px;height: 5px;background: #43D268;border-radius:50%;margin-right:5px"></span>
                  已完结
                </div>
              `
                        }
                    },
                    {
                        key: 'type',
                        text: '工单类型'
                    },
                    {
                        key: 'doDept',
                        text: '受理部门'
                    },
                    { key: 'doMan', text: '当前处理人' },
                    { key: 'phone', text: '处理人联系电话' },
                    { key: 'createTime', text: '创建时间' },
                    { key: 'updateTime', text: '更新时间' },
                    {
                        key: 'operate',
                        text: '操作',
                        width: '100px',
                        fixed: 'right',
                        template: () => {
                            return `
                  <el-button type="text" style="padding: 0;font-size: 12px; color: #0B83F3;" @click="changeType(row)" class="operate-btn">查看详情</el-button>
                `
                        }
                    }
                ],
                ajaxData: this.fetch,
                supportAjaxPage: true,
                dataKey: 'items',
                totalsKey: 'totalItem',
                height: '100%'
            },
            options: {
                searchItem: [
                    {
                        id: '0',
                        type: 'select',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择状态',
                        label: '状态',
                        value: '',
                        resField: 'state',
                        list: [
                            { value: '-1', name: '已关闭' },
                            { value: '0', name: '已发起' },
                            { value: '1', name: '已受理' },
                            { value: '2', name: '已转交' },
                            { value: '4', name: '已完结' }
                        ]
                    },
                    {
                        id: '1',
                        type: 'input',
                        size: 'small',
                        clearable: true,
                        placeholder: '请输入工单编号',
                        label: '工单编号',
                        value: '',
                        resField: 'snNumber'
                    },
                    {
                        id: '2',
                        type: 'cascader',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择',
                        label: '工单类型',
                        value: '',
                        multiple: true,
                        collapseTags: true,
                        filterable: true,
                        resField: 'type',
                        separator: '-',
                        list: [],
                        props: {
                            label: 'label',
                            value: 'value',
                            children: 'children',
                            checkStrictly: true
                        }
                    },
                    {
                        id: '3',
                        type: 'select',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择',
                        label: '超时受理/超时处理',
                        value: '',
                        resField: 'doState',
                        list: [
                            { value: '0', name: '超时受理' },
                            { value: '1', name: '超时处理' }
                        ]
                    },
                    {
                        id: '8',
                        type: 'daterange',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择创建时间范围',
                        label: '创建时间',
                        value: '',
                        resField: 'date'
                    },
                    {
                        id: '9',
                        type: 'daterange',
                        size: 'small',
                        clearable: true,
                        placeholder: '请选择更新时间范围',
                        label: '更新时间',
                        value: '',
                        resField: 'upDate'
                    }
                ],
                // 确认按钮
                sureBtnVisable: true,
                sureBtnType: 'primary',
                sureBtnSize: 'medium',
                sureBtnText: '查询',
                //重置按钮
                restBtnVisable: true,
                restBtnType: '',
                restBtnSize: 'medium',
                restBtnText: '重置'
            },
            queryKey: {},
            AddCursoreModule: false,
            modalParams: null,
            formState: null,
            typeForm: null
        }
    },
    created() {
        this.fetchPaper()
    },
    methods: {
        // fetch(params) {
        //     return new Promise(async (resolve,reject)=>{
        //         try {
        //             let reqData = {
        //             page: params.pageData.cPage,
        //             pageSize: params.pageData.pSize,
        //             model:1,
        //             }
        //             for (let key in this.queryKey) {
        //             if (key && key != "date" && key != "upDate") {
        //                 reqData[key] = this.queryKey[key];
        //             }
        //             if (this.queryKey.date) {
        //     reqData["createTimeStart"] = this.queryKey.date[0];
        //     reqData["createTimeEnd"] = this.queryKey.date[1];
        //   }
        //   if (this.queryKey.upDate) {
        //     reqData["updateStartTime"] = this.queryKey.upDate[0];
        //     reqData["updateEndTime"] = this.queryKey.upDate[1];
        //   }
        //         }
        //             let data = await reimbursePerson(reqData)
        //             const tableData = {
        //                 data: data['items'],
        //                 totals: Number(data.totalItem)|| 0,
        //             };
        //             resolve(tableData)
        //         }catch(e) {
        //             reject("data err");
        //         }
        //     })
        // },

        fetch(params) {
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                model: 1
            }
            for (let key in this.queryKey) {
                if (key && key != 'date' && key != 'upDate') {
                    reqData[key] = this.queryKey[key]
                }
                if (this.queryKey.date) {
                    reqData['createTimeStart'] = this.queryKey.date[0]
                    reqData['createTimeEnd'] = this.queryKey.date[1]
                }
                if (this.queryKey.upDate) {
                    reqData['updateStartTime'] = this.queryKey.upDate[0]
                    reqData['updateEndTime'] = this.queryKey.upDate[1]
                }
            }
            return reimbursePerson(reqData)
        },

        closeDialog(refresh) {
            this.AddCursoreModule = false
            if (refresh) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        fetchPaper() {
            wosQueryType({})
                .then((data) => {
                    if (data && data.items && data['items'].length) {
                        this.options.searchItem[2].list = data['items']
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({ type: 'error', message: err, duration: 2000, showClose: true })
                })
        },
        changeType(row) {
            this.billDetailsModule = true
            this.modalParams = row
        },
        addnew() {
            this.modalParams = null
            this.AddCursoreModule = true
        },
        getFormState(e) {
            this.formState = e
        },
        getState(e) {
            this.typeForm = e
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        }
    }
}
</script>

<style lang="scss" scoped>
.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-tab {
        display: flex;
        align-items: center;
        font-size: 14px;
        .tab-item {
            line-height: 36px;
            text-align: center;
            margin-right: 3px;
            cursor: pointer;
            padding: 0 20px;
        }
    }
    .top-btn {
        display: flex;
        align-items: center;
        .el-button {
            background: #067cf2;
            padding: 0 20px;
            line-height: 36px;
            color: #fff;
            border-radius: 0;
            border: none;
        }
    }
}
</style>
