<template>
  <div class="conatiner">
    <el-drawer :title="modalParams ? '修改课程' : '新建工单'" :visible.sync="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" :wrapperClosable="false" @close="close" size="40%">
      <el-form class="form" style="margin-top:-20px;">
        <div class="form-item" style="display: flex;flex-direction: column;align-items: flex-start;">
          <div class="label-box">
            <p>选择工单类型<span>*</span></p>
          </div>
          <el-cascader v-model="queryForm.pId" :options="options" :props="{
            multiple: false,
            value: 'value',
            label: 'label',
            emitPath: false,
            children: 'children',
            checkStrictly: true,
          }" clearable collapse-tags :show-all-levels="false" style="width: 250px" filterable @change="change">
          </el-cascader>
        </div>
        <div style="width: 100%; background-color: #ffffff" v-if="this.quesion">
          <div style="padding:10px 10px 16px 0; font-weight: bold;">FAQ问题列表</div>
          <div>
            <grid-manager :option="gridOption"></grid-manager>
          </div>
        </div>
        <div class="form-item" style="margin-top: -10px;">
          <div class="form-item" style="display: flex; flex-direction: column; align-items:flex-start ;">
            <div class="label-box" style="padding-bottom: 10px">
              <p>是否立即联系<span>*</span></p>
            </div>
            <el-radio-group v-model="queryForm.isOpenComment">
              <el-radio :label="0">随时联系</el-radio>
              <el-radio :label="1">指定时间</el-radio>
            </el-radio-group>
          </div>
         </div>
          <div v-if="queryForm.isOpenComment"  class="form-item" style="display: flex; flex-direction: column; align-items:flex-start;
          margin-top: 10px;">
            <div class="label-box">
              <p style="padding-bottom: 10px">选择时间<span>*</span></p>
            </div>
              <el-date-picker v-model="queryForm.date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd h" size='small'>
              </el-date-picker>
          </div>

        <div class="form-item" style="display: flex; flex-direction: column; align-items:flex-start; margin-top: 10px;">
          <div class="label-box">
            <p>问题描述</p>
          </div>
          <el-input type="textarea" :rows="4" :maxlength="100" show-word-limit placeholder="请输入描述"
            v-model="queryForm.purpose"></el-input>
        </div>
        <div class="form-item" style="display: flex; flex-direction: column; align-items:flex-start ;">
          <div style="color: #333; font-size: 14px; width: 100px;padding-bottom: 10px;">上传图片</div>
          <el-upload ref="upload" list-type="picture-card" name="resource" :data="{
            resourceType: 'WOS',
          }" :limit="9" :action="urlRoots + uploadResources" :headers="uploadHeaders" :auto-upload="true"
            :on-preview="handlePictureCardPreview" :file-list="fileListImg" :on-remove="
              (file, fileList) => {
                removeImg(file, fileList);
              }
            " :on-exceed="
              (file) => {
                limitNumb(file);
              }
            " :on-success="
              (res, file) => {
                imgUploadSuccess(res);
              }
            ">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="prevImage" :append-to-body="true">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
        <div class="form-item" style="display: flex; flex-direction: column; align-items:flex-start ;">
          <div style="color: #333; font-size: 14px; width: 100px; padding-bottom: 10px;">上传视频</div>
          <el-upload ref="uploadVideo" :action="urlRoots + uploadResources" :headers="uploadHeaders" :data="{
            resourceType: 'WOS',
          }" name="resource" :auto-upload="true" :file-list="videoFileList" :limit="9" :on-remove="videoRemove"
            :on-success="videoUploadSuccess" :on-error="videoUploadError" :show-file-list="false">
            <el-button type="primary">选择视频上传</el-button>
          </el-upload>
        </div>
        <div v-if="chooseVideoList.length" style="margin: 30px 100px 0">
          <div v-for="(item, v) in chooseVideoList" :key="v"
            style="width: 140px; margin-right: 20px; position: relative">
            <video :src="item" preload controls="controls" style="width: 140px; height: 140px; margin-right: 10px"
              id="video"></video>
            <span @click="delChooseItem(v, 'video')" class="el-icon-circle-close" style="
                position: absolute;
                top: -8px;
                right: -8px;
                cursor: pointer;
                font-size: 20px;
              ">
            </span>
          </div>
        </div>
      </el-form>
      <div style="
        position: absolute;
        bottom: 20px;
        right: 3px;
          margin-right: 40px;
          display: flex;
          align-items: center;
          justify-content: end;
        ">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="beforeSubmit">确 定</el-button>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisiblequetion" width="30%" :title="videoquesion.courseName"
      :before-close="handleClose" v-if="dialogVisiblequetion">
      <video width="500" height="300" controls>
        <source :src="videoquesion.courseContent" type="video/mp4" />
      </video>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibletext" width="40%" :title="videoquesion.courseName"
      :before-close="handleClose" v-if="dialogVisibletext" :close-on-click-modal="false">
      <div v-html="videoquesion.courseContent" class="htmlImage" id="imgs"></div>
    </el-dialog>
  </div>
</template>
<script>
import {
  wosQueryType,
  submitWos,
  reimburse,
  userOperate,
  uploadResource,
} from "@/libs/http/api.js";
import http from "@/libs/http/index.js";
// import Db from "@/libs/common/dataStore";
export default {
  props: ["modalParams"],
  components: {
    wosQueryType,
    submitWos,
    reimburse,
  },
  data() {
    return {
      dialogVisibletext: false,
      videoquesion: null,
      clickgood: false,
      dieosrc: "",
      quesiontlite: "",
      dialogVisiblequetion: false,
      disabled: false,
      typeDialog: false,
      isCourseLabel: false,
      quesion: false,
      queryForm: {
        date: [],
        pId: "",
        purpose: "",
        isOpenComment: 0,
      },
      gridOption: {
        gridManagerName: "courser",
        firstLoading: true,
        isCombSorting: true,
        supportCheckbox: false,
        supportAutoOrder: true,
        lineHeight: "30px",
        columnData: [
          { key: "courseName", text: "问题", width: "10px" },
          {
            key: "operate",
            text: "操作",
            fixed: "right",
            width: "200px",
            template: () => {
              return `
              <div style=" display: flex ;
                  justify-items: center;
                  flex-direction: row;
                  flex-wrap: nowrap;">
                 <el-button type="text" style="padding-right:13px;font-size: 14px; color: #0B83F3;" @click="closeitem(row)" class="operate-btn">查看内容</el-button>
                <div style='display:flexd;justify-content: center; margin:3px 20px 3px 0;cursor:pointer;'>
                <i type="text" style="font-size: 20px;display: inline;padding-top:10px " @click="closeLike(row)" class="iconfont icon-zan-" :style="{color: row.state==1?'#0B83F3':'balck'}"></i>
                <i style='font-size:14px;font-style: normal'>{{row.state==1?'已赞':'赞'}}<i>
                </div>
                <div style='display:flexd;justify-content: center;justify-items: center;flex-wrap: nowrap;margin:3px 10px 3px 0;cursor:pointer;'>
                <i style="padding-top: -20px;font-size: 20px;" @click="closetread(row)" class="iconfont icon-caishixin-" :style="{color: row.state!=1&&row.state==-1?'red':'balck'}"></i>
                <i style='font-size:14px;font-style: normal'>{{row.state!=1&&row.state==-1?'已踩':'踩'}}<i>
                </div>
                </div>

              `;
            },
          },
        ],
        dataKey: "data",
        ajaxData: this.fetch, //数据
        // 分页
        supportAjaxPage: true,
        // 高度
        height: "300px",
      },
      dialogVisible: true,
      loading: false,
      totalItem: 0,
      options: [],

      dialogImageUrl: "",
      prevImage: false,
      uploadHeaders: {
        // "X-Token": Db.get("userData.token").value(),
      },
      imgUploadSrc: [], //传给后端的图片列表
      fileListImg: [], //编辑时展示图片列表
      urlRoots: "",
      uploadResources: "",

      chooseVideoList: [],
      videoFileList: [],
    };
  },
  created() {
    this.urlRoots = http.urlRoot;
    console.log(this.urlRoots);

    this.uploadResources = uploadResource;
    this.parentDirectory();
  },

  methods: {
    delChooseItem(index, type) {
      this.chooseVideoList.splice(index, 1);
    },
    videoRemove(file, fileList) {
      this.videoFileList = fileList;
      this.chooseVideoList.splice(
        this.chooseVideoList.findIndex((item) => item === file.url),
        1
      );
    },
    videoUploadSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.videoFileList = fileList;
        const data = JSON.parse(res.data);
        const viedoUrl = data.resourceUrlRoot + data.resourceUrlPath;
        console.log(viedoUrl)
        this.$message.success("视频上传成功");
        if (this.chooseVideoList.indexOf(viedoUrl) == -1) {
          this.chooseVideoList.push(viedoUrl);
        }
      } else {
        this.$message.error("视频上传失败");
      }
    },
    videoUploadError(err, file, fileList) {
      this.$log.error(JSON.stringify(err));
      this.$message.error("上传失败");
    },
    imgUploadSuccess(res) {
      if (res.code == 200) {
        this.$message.success("上传成功");
        const data = JSON.parse(res.data);
        const imgUrl = data.resourceUrlRoot + data.resourceUrlPath;
        if (this.imgUploadSrc.indexOf(imgUrl) == -1) {
          this.imgUploadSrc.push(imgUrl);
        }
      } else {
        this.$message.error("图片上传失败");
      }
    },
    limitNumb() {
      this.$message.error("超出上传数量");
    },
    removeImg(file, fileList) {
      this.fileListImg = fileList;
      this.imgUploadSrc.splice(
        this.imgUploadSrc.findIndex((item) => item === file.url),
        1
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.prevImage = true;
    },
    closeitem(row) {
      if (row.courseType == 2) {
        this.videoquesion = row;
        this.dialogVisiblequetion = true;
      } else {
        this.videoquesion = row;
        this.dialogVisibletext = true;
      }
    },
    handleClose() {
      if (this.dialogVisiblequetion) {
        this.dialogVisiblequetion = false;
      } else {
        this.dialogVisibletext = false;
      }
    },
    change() {
      GridManager.refreshGrid(this.gridOption.gridManagerName, true);
      this.fetch();
    },
    fetch() {
      return new Promise(async (resolve, reject) => {
        try {
          let reqData = {
            typeId: this.queryForm.pId,
          };
          let data = await reimburse(reqData);
          if (data["items"].length > 0) {
            this.quesion = true;
          } else {
            this.quesion = false;
          }
          const tableData = {
            data: data["items"],
            totals: Number(data.totalItem) || 1,
          };
          resolve(tableData);
        } catch (e) {
          this.quesion = false;
        }
      });
    },
    getValue() { },
    // 点赞
    closeLike(row) {
      console.log(row);
      let reqData = {
        objectId: row.courseId,
        objectType: 1,
        type: 1,
      };
      userOperate({
        ...reqData,
      })
        .then((data) => {
          if (data.state == 1) {
            this.$message({
              type: "success",
              message: "点赞成功",
              duration: 2000,
              showClose: true,
            });
            this.clickgood = true;
            GridManager.refreshGrid(this.gridOption.gridManagerName, true);
          } else {
            this.$message({
              type: "success",
              message: "取消点赞成功",
              duration: 2000,
              showClose: true,
            });
            this.clickgood = false;
            GridManager.refreshGrid(this.gridOption.gridManagerName, true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    // 点踩数量
    closetread(row) {
      console.log(row);
      let reqData = {
        objectId: row.courseId,
        objectType: 1,
        type: -1,
      };
      userOperate({
        ...reqData,
      })
        .then((data) => {
          if (data.state == -1) {
            this.$message({
              type: "success",
              message: "点踩成功",
              duration: 2000,
              showClose: true,
            });
            GridManager.refreshGrid(this.gridOption.gridManagerName, true);
          } else {
            this.$message({
              type: "success",
              message: "取消点踩成功",
              duration: 2000,
              showClose: true,
            });
            GridManager.refreshGrid(this.gridOption.gridManagerName, true);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },

    parentDirectory() {
      let subObj = {};
      wosQueryType({
        items: [subObj],
      })
        .then((data) => {
          if (data && data.items && data['items'].length) {
            this.options = data["items"];
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    close() {
      this.dialogVisible = false;
      this.$emit("close");
    },

    beforeSubmit() {
      if (!this.queryForm.pId) {
        return this.$message.error("请选择工单类型");
      }
      if (this.queryForm.isOpenComment) {
        if (this.queryForm.date.length <= 0) {
          return this.$message.error("请输入开始与结束时间");
        }
      }
      let reqData = {
        typeId: this.queryForm.pId,
        content: this.queryForm.purpose,
        appointedType: this.queryForm.isOpenComment,
      };
      if (this.queryForm.isOpenComment) {
        reqData["appointedStartTime"] = this.queryForm.date[0];
        reqData["appointedEndTime"] = this.queryForm.date[1];
      }
      if (this.imgUploadSrc && this.imgUploadSrc.length > 0) {
        reqData.picture = this.imgUploadSrc.join(",");
      }
      if (this.chooseVideoList && this.chooseVideoList.length > 0) {
        reqData.video = this.chooseVideoList.join(",");
      }
      this.submit(reqData);
    },
    submit(reqData) {
      this.loading = true;
      submitWos({
        ...reqData,
      })
        .then((data) => {
          this.dialogVisible = false;
          this.$emit("close", true);
          this.$message({
            type: "success",
            message: "提交成功",
            duration: 2000,
            showClose: true,
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.htmlImage {
  overflow: auto;

  img {
    width: 80%;
    height: auto;

  }
}

.clickgood {
  color: red;
}

.iconfont {
  width: 50px;
  height: 50px;
}

.form {
  padding: 2px 20px;
  overflow: auto;

  .form-item {
    margin-bottom: 10px;
    display: flex;

    // flex-direction: column;
    .label-box {
      height: 36px;
      width: 100px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      font-size: 14px;

      span {
        color: red;
        margin-left: 2px;
      }
    }
  }
  .good:hover {
    padding-top: 10px;
  }
}
</style>
