<template>
    <div>
        <el-drawer title="工单详情" :visible.sync="drawer" @close="close" size="60%" :wrapperClosable="false"
            :append-to-body="true">
                <!-- 按钮 -->
                <div style="position: fixed; top: 15px; right: 100px" class="row align-center"
                    v-if="buttons.length > 0">
                    <el-button v-if="buttons.indexOf('6') > -1" @click="closeitem"
                        style="background-color:#f8475f; color: white;">关闭工单</el-button>

                    <el-button v-if="buttons.indexOf('9') > -1" @click="evaluate"
                        style="background-color:#43D268; color: white;">评价工单</el-button>
                    <el-button v-if="buttons.indexOf('5') > -1" @click="complaint"
                        style="background-color:#FF9000; color: white;">投诉工单</el-button>
                </div>
                <!-- 判断状态 -->
                <div class="time-line row space-center" v-if="wosDetail">
                    <div v-for="(item, index) in wosDetail.wosFlowDetails" :key="index" class="row space-center">
                        <div class="column align-center">
                            <div class="time-circle" :style="{
                                background:
                                    item.wosRecordState == '4' || item.wosRecordState == '-1'
                                        ? '#ccc'
                                        : '',
                            }">
                                {{ index + 1 }}
                            </div>
                            <div class="time-type">
                                {{
                                item.wosRecordState == "0"
                                ? "已发起"
                                : item.wosRecordState == "1"
                                ? "已受理"
                                : item.wosRecordState == "2"
                                ? "已处理"
                                : item.wosRecordState == "3"
                                ? "已转交"
                                : item.wosRecordState == "4"
                                ? "已完结"
                                : item.wosRecordState == "-1"
                                ? "已关闭"
                                : ""
                                }}
                            </div>
                            <div class="time-date" v-if="item.triggerTime">
                                {{ item.triggerTime }}
                            </div>
                            <div class="time-date" style="color: #333" v-if="item.consumeTime">
                                {{ item.consumeTime }}
                            </div>
                            <div class="time-date" style="color: red" v-if="item.overTime">
                                {{ item.overTime }}
                            </div>
                            <div class="time-date" style="color: #ccc"
                                v-if="item.wosRecordState == '4' || item.wosRecordState == '-1'">
                                {{
                                item.wosRecordState == "4"
                                ? "已完结"
                                : item.wosRecordState == "-1"
                                ? "已关闭"
                                : ""
                                }}
                            </div>
                        </div>
                        <div style="
                  width: 70px;
                  margin: 10px 20px;
                  height: 1px;
                  background: #027db4;
                " v-if="index != wosDetail.wosFlowDetails.length - 1"></div>
                    </div>
                </div>
                <div
            class="row"
            style="margin-left: 20px; margin-top: 20px"
            v-for="(item, index) in wosInfoLogs"
            :key="index"
          >
            <div
              class="column space-center align-center"
              style="height: 100%;margin-top:3px;"
            >
              <div
                class="row align-center space-center"
                style="
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  border: 1px solid #ccc;
                "
              >
                <div class="round row align-center space-center"></div>
              </div>
              <div class="round-line"></div>
            </div>
            <div class="credentials">
              <div style="color: #333;font-weight:bold; font-size: 14px; width: 100%">
                <span style="margin-right: 40px" v-if="item.submitDeptDetail">发起人部门：{{ item.submitDeptDetail }}</span>
                <span style="margin-right: 40px" v-if="item.dealDeptDetail">处理人部门：{{ item.dealDeptDetail }}</span>
                <div style="color: #f59f4d; float: right">
                  <span style="
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background: #f59f4d;
                      margin-right: 5px;
                      display: inline-block;
                      margin-bottom: 3px;
                    "></span>
                  {{ item.state }}
                </div>
              </div>
              <div style="
                  width: 100%;
                  flex-wrap: wrap;
                  margin-top: 10px;
                  background: #f6f8fa;
                  padding: 5px 15px 15px;
                " class="row">
                <div class="card-infos row" v-if="item.submitUserName">
                  <div class="info-titles">发起人</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.submitUserName }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.dealUserName">
                  <div class="info-titles">处理人</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.dealUserName }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.userPhone">
                  <div class="info-titles">联系电话</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.userPhone }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.connectTime">
                  <div class="info-titles">是否立即联系</div>
                  <div style="color: #333; font-size: 14px;color: red;">
                    {{ item.connectTime }}
                  </div>
                </div>

                <div class="card-infos row">
                  <div class="info-titles">工单类型</div>
                  <div style="color: #333; font-size: 14px;width:220px">
                    {{ item.typeTitleDetail }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.transferDeptDetail">
                  <div class="info-titles">移交部门</div>
                  <div style="color: #333; font-size: 14px;width:220px">
                    {{ item.transferDeptDetail }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.allocateDeptDetail">
                  <div class="info-titles">分配部门</div>
                  <div style="color: #333; font-size: 14px;width:220px">
                    {{ item.allocateDeptDetail }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.complaintUserName">
                  <div class="info-titles">被投诉人</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.complaintUserName }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.arbitrationJudge">
                  <div class="info-titles">仲裁评判人</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.arbitrationJudge }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.handlePoint">
                  <div class="info-titles">评分</div>
                  <div style="color: #333; font-size: 14px">
                    {{ Math.trunc(item.handlePoint)+'分' }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.picture && item.picture.length > 0">
                  <div class="info-titles">上传图片</div>
                  <div style="
                      text-decoration: underline;
                      color: #027db4;
                      font-size: 14px;
                      cursor: pointer;
                    " @click="
                      guidePic = item.picture;
                      guidePic
                        ? (showViewer = true)
                        : $message.info('当前没有可预览的图片');
                    ">
                    点击查看
                  </div>
                </div>
                <div class="card-infos row" v-if="item.video && item.video.length > 0">
                  <div class="info-titles">上传视频</div>
                  <div style="
                      font-size: 14px;
                      text-decoration: underline;
                      cursor: pointer;
                      color: #027db4;
                    " @click="
                      videoVisible = true;
                      videoData = item.video;
                    ">
                    点击查看
                  </div>
                </div>
                <div class="card-infos row" v-if="item.audio && item.audio.length > 0">
                  <div class="info-titles">上传语音</div>
                  <div style="
                      font-size: 14px;
                      text-decoration: underline;
                      color: #027db4;
                      cursor: pointer;
                    " @click="
                      audioVisible = true;
                      audioData = item.audio;
                    ">
                    点击查看
                  </div>
                </div>
                <div class="card-infos row">
                  <div class="info-titles">处理时间</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.createTime }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.consumeTime">
                  <div class="info-titles">耗时时间</div>
                  <div style="color: #333; font-size: 14px">
                    {{ item.consumeTime }}
                  </div>
                </div>
                <div class="card-infos row" v-if="item.overTime">
                  <div class="info-titles">超时时间</div>
                  <div style="color: #F8475F; font-size: 14px">
                    {{ item.overTime }}
                  </div>
                </div>
                <div class="card-infos row" style="width: 100%">
                  <div class="info-titles">描述</div>
                  <div style="color: #666; font-size: 14px; width: 650px">
                    {{ item.content }}
                  </div>
                </div>
              </div>
            </div>
                    </div>
        </el-drawer>
        <el-dialog title="评分单" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-form label-position="left" label-width="auto">
                <el-form-item label="工单评分" prop="courseId">
                    <el-input-number v-model="form.paperNumber" style="width: 80%" :max="100" :min="0">
                    </el-input-number>
                    <span style="color: red; margin-left:15px ">*最高100分</span>
                </el-form-item>
                <el-form-item label="评价内容" prop="wosType">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.textarea" maxlength="200"
                        style="width:80%" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitevaluate">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="投诉单" :visible.sync="dialogVisibleplants" width="30%" :before-close="handleCloseplants">
            <el-form label-position="left" label-width="auto">
                <el-form-item label="处理人" prop="courseId">
                    <el-select v-model="form.defaultHandleDept" placeholder="请选择" collapse-tags clearable
                        style="width: 100%">
                        <el-option v-for="(item, index) in deptList" :key="index" :value="item.userId"
                            :label="item.userName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="投诉理由" prop="wosType">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.textarea" maxlength="200"
                        style="width:100%" show-word-limit>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleplants = false">取 消</el-button>
                <el-button type="primary" @click="submitplant">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="语音列表" :visible.sync="audioVisible" width="30%" @close="audioVisible = false"
            :close-on-click-modal="false">
            <el-table :data="audioData" border>
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column property="date" label="音频地址">
                    <template slot-scope="url">
                        {{ url.row }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button type="text" @click="playAudio(scope.row)" size="small">播放</el-button>
                        <el-button type="text" @click="stopAudio(scope.row)" size="small">停止播放</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="视频列表" :visible.sync="videoVisible" width="30%" @close="videoVisible = false"
            :close-on-click-modal="false">
            <el-table :data="videoData" border>
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column property="date" label="视频地址可直接复制到浏览器进行观看">
                    <template slot-scope="url">
                        {{ url.row }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <video :src="scope.row" preload controls="controls"
                            style="width: 130px; height: 70px; margin-right: 10px" id="video"></video>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-image-viewer :on-close="showViewerClose" v-if="showViewer === true" :url-list="guidePic"
            style="z-index: 9999999"></el-image-viewer>
        <HandOver v-if="showHandOver" @close="handOverClose" :wosRecordId="modalParams.id" :wosDealType="wosDealType">
        </HandOver>
    </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
//   import HandOver from "./handOver.vue";
import { detailInfo, dealWos, complaint } from "@/libs/http/api.js";
let audio = null;
export default {
    components: {
        ElImageViewer,
        //   HandOver,
    },
    props: ["modalParams"],
    data() {
        return {
            form: {
                paperNumber: '',
                textarea: '',
                defaultHandleDept: ''
            },
            deptList: [],
            dialogVisible: false,
            dialogVisibleplants: false,
            drawer: false,
            wosDetail: {},
            wosInfoLogs: [],
            buttons: [],
            showViewer: false,
            guidePic: [],
            audioVisible: false,
            audioData: [],
            videoVisible: false,
            videoData: [],
            showHandOver: false,
            wosDealType: "",
        };
    },
    created() {
        this.drawer = true;
        this.fetch();
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
            this.fetch()
        },
        evaluate() {
            this.dialogVisible = true
        },
        submitevaluate() {
            // if (!this.form.paperNumber) {
            //     return this.$message.error("请输入评分分数");
            // }
            // if (!this.form.textarea) {
            //     return this.$message.error("请输入评价内容");
            // }
            let reqData = {
                wosDealType: 9,
                wosRecordId: this.modalParams.id,
                handlePoint: this.form.paperNumber,
                content: this.form.textarea
            }
            dealWos({
                ...reqData
            }).then(data => {
                this.$message({ type: 'success', message: '评分成功', duration: 2000, showClose: true })
                this.dialogVisible = false
                this.fetch()
            }).catch(err => {
                this.loading = false;
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true });
            })
        },
        complaint(row) {
            this.dialogVisibleplants = true
            this.complaintjie()
        },
        submitplant() {
            if (!this.form.defaultHandleDept) {
                return this.$message.error("请输入处理人");
            }
            if (!this.form.textarea) {
                return this.$message.error("请输入投诉理由");
            }
            let reqData = {
                wosDealType: 5,
                wosRecordId: this.modalParams.id,
                complaintUserId: this.form.defaultHandleDept,
                content: this.form.textarea
            }
            dealWos({
                ...reqData
            }).then(data => {
                this.$message({ type: 'success', message: '投诉成功', duration: 2000, showClose: true })
                this.fetch()
                this.dialogVisibleplants = false

            }).catch(err => {
                this.loading = false;
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true });
            })
        },
        complaintjie() {
            let reqData = {
                wosRecordId: this.modalParams.id,
            }
            complaint({
                ...reqData
            }).then(data => {
                this.deptList = data
            }).catch(err => {
                this.loading = false;
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true });
            })
        },
        handOverClose(refresh) {
            this.showHandOver = false;
            if (refresh) {
                this.fetch();
            }
        },
        playAudio(url) {
            audio = new Audio();
            audio.src = url;
            audio.play();
        },
        stopAudio() {
            audio.pause();
        },
        showViewerClose() {
            this.showViewer = false;
        },
        handleCloseplants() {
            this.dialogVisibleplants = false
            this.fetch()
        },
        close() {
            this.drawer = false;
            this.$emit("close");
        },
        fetch() {
            let reqData = {
                wosRecordId: this.modalParams.id,
            }
            detailInfo({
                ...reqData
            }).then(data => {
                console.log(data);
                this.wosDetail = data.wosDetail;
                this.wosInfoLogs = data.wosInfoLogs;
                this.buttons = data.buttons;
                console.log(this.wosInfoLogs, '1111');
                console.log(this.wosDetail, '222');
                console.log(this.buttons, '333');

            }).catch(err => {
                this.loading = false;
                this.$message({ type: 'error', message: err, duration: 2000, showClose: true });

            })
            // this.$http.post(
            //   this.$macro.api.queryWosDetailInfo,
            //   {
            //     wosRecordId: this.modalParams.id,
            //   },
            //   (data) => {
            //     this.wosDetail = data.wosDetail;
            //     this.wosInfoLogs = data.wosInfoLogs;
            //     this.buttons = data.buttons;
            //   },
            //   (errorDesc) => {
            //     this.$message.error(errorDesc);
            //   },
            //   (error) => {
            //     this.$message.error(error);
            //   },
            //   this
            // );

        },

        closeitem(row) {
            let reqData = {
                wosDealType: 6,
                wosRecordId: this.modalParams.id
            }
            this.$confirm("是否关闭该工单?", "提示", {})
                .then(() => {
                    dealWos({
                        ...reqData
                    }).then(data => {
                        this.$message({ type: 'success', message: '关闭成功', duration: 2000, showClose: true })
                        this.fetch()

                    }).catch(err => {
                        this.loading = false;
                        this.$message({ type: 'error', message: err, duration: 2000, showClose: true });
                    })
                })
                .catch(() => { });
            console.log(row);
        },
    },
};
</script>
<style lang="scss" scope>
.time-line {
    margin: 30px 0;

    .time-circle {
        background: #027db4;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
    }

    .time-type {
        margin-top: 10px;
        color: #333333;
    }

    .time-date {
        color: #999;
        font-size: 13px;
    }

    .time-describe {
        color: red;
        font-size: 13px;
    }
}

.card-top {
    margin: 20px 0 0;

    .card-line {
        width: 2px;
        height: 15px;
        background: #027db4;
        margin-right: 20px;
    }

    .card-title {
        font-size: 18px;
    }
}

.card-info {
    margin: 10px 0;
    width: calc(100% / 3);

    .info-title {
        color: #999;
        width: 100px;
        text-align: left;
    }
}

.round {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #067cf2;
}

.round-line {
    background: #067cf2;
    width: 1px;
    height: 108px;
    margin: 10px 0;
}

.credentials {
    margin: 0px 20px;
    width: calc(100%);
}

.card-infos {
    width: calc(100% / 3);
    margin-top: 10px;
}

.info-titles {
    color: #999;
    width: 120px;
    text-align: left;
    font-size: 14px;
}

.form-item {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .label-box {
        width: 100px;
        display: flex;

        span {
            color: red
        }
    }

    .feng {
        margin-left: 10px;
        width: 100px;
        color: red
    }
}
</style>